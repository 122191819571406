import React from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { BccButton, BccTypography } from "./BccComponents";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "0 40px",
        maxWidth: 1280,
        boxSizing: "border-box",
        '& > span': {
          display: 'block',
          textAlign: 'center',
          padding: '48px 0'
        }
      },
      footer: {
        borderRadius: 16,
        background: '#F4F4F4',
        padding: '32px 48px',
        '& > div:first-child': {
          width: '40%',
          '& > h1': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 700,
            fontSize: 32,
            lineHeight: "48px",
            margin: 0,
            color: 'black',
            marginBottom: 16,
            letterSpacing: '-2px',
          },
          '& > p': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: "32px",
            margin: 0,
            color: 'black',
          }
        },
        '& > div:last-child': {
          width: 'auto',
          marginRight: 60,
          '& > h1': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 700,
            fontSize: 32,
            lineHeight: "48px",
            margin: 0,
            color: 'black',
            marginBottom: 24,
            letterSpacing: '-2px',
          }
        }
      },
      social: {
        '& > div': {
          width: 'calc(33% - 13px)',
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        padding: "0 20px",
        '& > span': {
          padding: '24px 0'
        }
      },
      footer: {
        padding: '24px 16px',
        flexDirection: 'column',
        '& > div:first-child': {
          width: '100%',
          '& > h1': {
            fontSize: 24,
            margin: 0,
            marginBottom: 16,
          },
          '& > p': {
            marginBottom: 48,
          }
        },
        '& > div:last-child': {
          margin: 0,
          '& > h1': {
            fontSize: 24,
            marginBottom: 16,
          }
        }
      },
      social: {
        justifyContent: 'flex-start',
        '& > div': {
          width: 'auto',
          marginRight: 20
        },
        '& > div:last-child': {
          marginRight: 0
        }
      }
    },
  })
);

const Footer = () => {
  const classes = useStyles({});

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <Grid container justify="space-between" wrap="nowrap" className={classes.footer}>
          <Grid item>
            <h1>VITON</h1>
            <p>At VITON we come to work every day because we’re passionate about Augmented Reality and how it's changing the world as we know it. We love the role we get to play in the constantly evolving computer vision landscape and are excited about the next generation of AR applications that will be built using our technology.</p>
          </Grid>
          <Grid item>
            <h1>Follow us</h1>
            <Grid container justify="space-between" wrap="nowrap" className={classes.social}>
              <Grid item><img src={process.env.PUBLIC_URL + '/img/s-in.png'} alt="" /></Grid>
              <Grid item><img src={process.env.PUBLIC_URL + '/img/s-in.svg'} alt="" /></Grid>
              <Grid item><img src={process.env.PUBLIC_URL + '/img/s-tt.png'} alt="" /></Grid>
            </Grid>
          </Grid>
        </Grid>
        <span>2024 VITON</span>
      </div>
    </div>
  );
};

export default Footer;
