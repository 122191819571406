import React from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { BccButton, BccTypography } from "./BccComponents";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "0 40px 100px",
        maxWidth: 1280,
        boxSizing: "border-box",
      },
      boost: {
        padding: '80px 140px',
        borderRadius: 24,
        background: 'black',
        color: 'white',
        textAlign: 'center',
        '& > h1': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 700,
          fontSize: 48,
          lineHeight: "64px",
          letterSpacing: '-2px',
          margin: '0 0 32px',
        },
        '& > p': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 500,
          fontSize: 24,
          lineHeight: "48px",
          letterSpacing: '-1px',
          margin: '0 0 64px',
          color: '#969696'
        },
        '& > div': {
          width: '80%',
          margin: '0 auto',
          '& h2': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 700,
            fontSize: 48,
            lineHeight: "64px",
            letterSpacing: '-2px',
            margin: '0 0 24px',
          },
          '& p': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            fontSize: 24,
            lineHeight: "40px",
            letterSpacing: '-2px',
            margin: 0,
          }
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "0 20px 48px",
        maxWidth: 1280,
        boxSizing: "border-box",
      },
      boost: {
        padding: '40px 16px',
        '& > h1': {
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: '-2px',
          margin: '0 0 24px',
        },
        '& > p': {
          fontSize: 20,
          lineHeight: "32px",
          letterSpacing: '-1px',
          margin: '0 0 32px',
          '& > span': {
            color: 'white'
          }
        },
        '& > div': {
          width: '100%',
          '& h2': {
            fontSize: 32,
            margin: '0 0 12px',
          },
          '& p': {
            fontSize: 20,
            lineHeight: "32px",
            letterSpacing: '-1px',
          },
          '& > div': {
            width: '100%',
            marginBottom: 24,
          } 
        }
      }
    },
  })
);

const Boost = () => {
  const classes = useStyles({});

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.boost}>
          <h1>Boost your sales</h1>
          <p>When your customers use <span>VITON</span> technology, they can truly experience the product, significantly bringing them closer to making a purchase decision.</p>
          <Grid container justify="space-between">
            <Grid item>
              <h2>+50%</h2>
              <p>Engagement Rate</p>
            </Grid>
            <Grid item>
              <h2>+30%</h2>
              <p>Conversion Rate</p>
            </Grid>
            <Grid item>
              <h2>-8%</h2>
              <p>Return Rate</p>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Boost;
