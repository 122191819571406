import React from "react";
import { 
  AllInOne,
  Boost,
  Content,
  Get,
  Header,
  Highlight,
  Showcase,
  Steps,
  TryOn,
  Footer
 } from "./components";
import { animateScroll } from "react-scroll";

const App = () => {
  const tryRef: any = React.useRef(null);
  const viewRef: any = React.useRef(null);
  const solutionRef: any = React.useRef(null);
  const orderRef: any = React.useRef(null);
  const scrollToTry = () => {
    animateScroll.scrollTo(tryRef.current.offsetTop - 24);
  };
  const scrollTo360 = () => {
    animateScroll.scrollTo(viewRef.current.offsetTop - 24);
  };
  const scrollTo3d = () => {
    animateScroll.scrollTo(solutionRef.current.offsetTop - 24);
  };
  const scrollToOrder = () => {
    animateScroll.scrollTo(orderRef.current.offsetTop);
  };
  return (
    <div className="main-page">
      <div className="container">
        <Header scrollToTry={scrollToTry} scrollTo360={scrollTo360} scrollTo3d={scrollTo3d} scrollToOrder={scrollToOrder}/>
        <TryOn tryRef={tryRef}/>
        <Boost />
        <Highlight viewRef={viewRef} />
        <Showcase />
        <AllInOne solutionRef={solutionRef} />
        <Content />
        <Steps />
        <Get orderRef={orderRef} />
        <Footer />
      </div>
    </div>
  );
};

export default App;
