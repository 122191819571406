import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "0 40px 100px",
        maxWidth: 1280,
        boxSizing: "border-box",
      },
      showcase: {
        background: '#F4F4F4',
        borderRadius: 24,
        padding: '65px 100px 0',
        '& > div': {
          '& > div': {
            '& > h1': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 700,
              fontSize: 32,
              lineHeight: "48px",
              margin: '0 0 24px',
              color: 'black',
              letterSpacing: '-2px',
            },
            '& > p': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 500,
              fontSize: 24,
              lineHeight: "32px",
              margin: '0 0 48px',
              color: '#828282',
              letterSpacing: '-1px',
              width: '80%'
            },
            '& > button': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 600,
              fontSize: 16,
              lineHeight: "48px",
              padding: '0 24px',
              color: 'white',
              background: 'black',
              borderRadius: 12,
              cursor: 'pointer',
              border: 'none',
              outline: 'none',
              '&:hover': {
                background: '#606060',
              },
              '&:active, &:focus': {
                background: '#9B9B9B',
              }
            }
          }
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        padding: "0 20px 48px",
      },
      showcase: {
        background: 'white',
        borderRadius: 8,
        padding: 0,
        '& > div': {
          flexDirection: 'column-reverse',
          '& > div:first-child': {
          },
          '& > div:last-child': {
            marginBottom: 32,
            padding: "48px 48px 0",
            width: '100%',
            borderRadius: 8,
            background: '#F4F4F4',
            '& > img': {
              display: 'block',
              width: '100%'
            }
          },
        }
      }
    },
  })
);

const Showcase = () => {
  const classes = useStyles({});

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.showcase}>
          <Grid container justify="space-between" alignItems="center" wrap="nowrap">
            <Grid item>
              <h1>Showcase your 3D products in social media</h1>
              <p>Enhance your customers' shopping experience with the AR Masks</p>
              <button>Get a Demo</button>
            </Grid>
            <Grid item>
              <img src={process.env.PUBLIC_URL + '/img/sc.png'} alt="" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
