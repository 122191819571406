import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Model from 'react-3dmodelx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "0 40px 80px",
        maxWidth: 1280,
        boxSizing: "border-box",
        textAlign: 'center',
        '& > h1': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 700,
          fontSize: 32,
          lineHeight: "48px",
          margin: '0 0 32px',
          color: 'black',
          letterSpacing: '-2px',
        },
        '& > p': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 500,
          fontSize: 24,
          lineHeight: "32px",
          margin: '0 0 32px',
          color: '#828282',
          letterSpacing: '-1px',
        },
        '& > button': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 600,
          fontSize: 16,
          lineHeight: "48px",
          padding: '0 24px',
          color: '#3988FF',
          background: '#F4F4F4',
          borderRadius: 12,
          marginBottom: 32,
          cursor: 'pointer',
          border: 'none',
          outline: 'none',
          '&:hover': {
            background: '#E4E4E4',
          },
          '&:active, &:focus': {
            background: '#D4D4D4',
          }
        }
      },
      highlight: {
        '& > div': {
          width: 'calc(50% - 10px)',
          overflow: 'hidden',
          marginBottom: 20,
          backgroundColor: '#DDD4E7',
          borderRadius: 24,
          '& canvas': {
            display: 'block',
            height: '100%',
            width: 'auto',
            textAlign: 'center',
          }
        },
      }
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        padding: "0 20px 48px",
        textAlign: 'left',
        '& > h1': {
          margin: '0 0 24px',
        },
        '& > p': {
          fontWeight: 400,
          fontSize: 18,
          lineHeight: "28px",
          margin: '0 0 24px',
          letterSpacing: 'auto',
        },
      },
      highlight: {
        '& > div': {
          width: '100%',
          marginBottom: 32,
          '& > img': {
            display: 'block',
            width: '100%',
            textAlign: 'center'
          }
        }
      }
    },
  })
);

const Highlight = (props: any) => {
  const classes = useStyles({});

  return (
    <div className={classes.outerContainer} ref={props.viewRef}>
      <div className={classes.container}>
        <h1>Highlight Item Details with 360 View</h1>
        <p>Showcase your iconic watches, footwear, homeware or furniture in an engaging way with our new 3D Viewer. Let customers explore the details from every angle and get a better understanding of the details and texture of the product as if they had it in their hands.</p>
        <button>Know more</button>
        <Grid container justify="space-between" className={classes.highlight}>
          <Grid item>
            <Model.GLTF backgroundColor="#DDD4E7" isRotation={true} src={process.env.PUBLIC_URL + '/ar/nike.gltf'} />
          </Grid>
          <Grid item>
            <Model.GLTF backgroundColor="#FFE0D3" isRotation={true} src={process.env.PUBLIC_URL + '/ar/rolex.gltf'} />
          </Grid>
          <Grid item>
            <Model.GLTF backgroundColor="#D5DDE8" isRotation={true} src={process.env.PUBLIC_URL + '/ar/ps5.gltf'} />
          </Grid>
          <Grid item>
            <Model.GLTF backgroundColor="#E6E6E6" isRotation={true} src={process.env.PUBLIC_URL + '/ar/chair.gltf'} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Highlight;
