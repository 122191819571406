import React, { useRef } from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Model from 'react-3dmodelx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "100px 40px",
        maxWidth: 1280,
        boxSizing: "border-box",
        '& > div': {
          '& > div:first-child': {
            paddingRight: 26,
            '& > h1': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 700,
              fontSize: 32,
              lineHeight: "48px",
              margin: '0 0 32px',
              color: 'black',
              letterSpacing: '-2px',
            },
            '& > p': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 400,
              fontSize: 18,
              lineHeight: "28px",
              margin: '0 0 32px',
              color: '#374151',
            },
            '& > button': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 600,
              fontSize: 16,
              lineHeight: "48px",
              padding: '0 24px',
              color: '#3988FF',
              background: '#F4F4F4',
              borderRadius: 12,
              marginBottom: 32,
              cursor: 'pointer',
              border: 'none',
              outline: 'none',
              '&:hover': {
                background: '#E4E4E4',
              },
              '&:active, &:focus': {
                background: '#D4D4D4',
              }
            }
          },
          '& > div:last-child': {
            width: '50%',
            maxWidth: 800,
            height: 400,
            margin: 'auto'
          }
        }
      },
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        padding: "48px 20px",
        '& > div': {
          flexDirection: 'column-reverse',
          "& > div:last-child": {
            width: '100%',
            height: 360,
            maxWidth: 'auto',
            margin: '0 0 32px'
          },
          "& > div:first-child": {
            padding: 0,
            '& > h1': {
              marginBottom: 24
            },
            '& > p': {
              marginBottom: 24
            },
            '& > button': {
              marginBottom: 0
            }
          },
        }
      }
    },
  })
);

const Content = () => {
  const classes = useStyles({});

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <h1>3D Content Solutions</h1>
            <p>Create or find 3D Models easily with VITON. We have a huge database of 3D models, also we create highly realistic 3D models.</p>
            <button>Know More</button>
          </Grid>
          <Grid item>
            <Model.GLTF backgroundColor="white" isRotation={true} src={process.env.PUBLIC_URL + '/ar/nike.gltf'} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Content;
