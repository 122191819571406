import React, { useState } from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { BccButton, BccTypography } from "../components/BccComponents";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "40px 40px 112px",
        maxWidth: 1280,
        boxSizing: "border-box",
      },
      logo: {
        fontFamily: '"Helvetica Neue", "Arial", sans-serif',
        fontSize: 32,
        lineHeight: "40px",
        color: 'black'
      },
      lightBtn: {
        fontFamily: '"Inter", sans-serif',
        background: 'white',
        fontWeight: 400,
        fontSize: 24,
        lineHeight: "32px",
        color: 'black',
        padding: '12px 24px',
        border: '1px solid #DADADA',
        borderRadius: 20,
        cursor: 'pointer'
      },
      menu: {
        '& > span': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 400,
          fontSize: 24,
          lineHeight: "32px",
          color: 'black',
          marginRight: 40,
          cursor: 'pointer'
        },
        '& > a:last-child': {
          marginRight: 0
        },
      },
      blackBtn: {
        fontFamily: '"Inter", sans-serif',
        background: 'black',
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "40px",
        color: 'white',
        padding: '12px 32px',
        borderRadius: 20,
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        '&:hover': {
          background: '#606060',
        },
        '&:active, &:focus': {
          background: '#9B9B9B',
        }
      },
      header: {
        width: '80%',
        margin: '134px auto 0',
        textAlign: 'center',
        '& > h1': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 700,
          fontSize: 72,
          lineHeight: "80px",
          color: 'black',
          letterSpacing: '-2px',
          margin: 0,
        },
        '& > h2': {
          fontFamily: '"Inter", sans-serif',
          color: '#969696',
          fontWeight: 500,
          fontSize: 24,
          lineHeight: "40px",
          letterSpacing: '-1px',
          margin: '32px 0 48px',
        }
      },
      menuIcon: {
        display: 'none'
      }
    },
    [theme.breakpoints.down("xs")]: {
      logo: {
        fontSize: 24,
        fontWeight: 500,
        lineHeight: "40px",
        textDecoration: 'none',
        color: 'black',
        letterSpacing: '-1px',
      },
      header: {
        margin: '40px 0 0',
        textAlign: 'left',
        '& > h1': {
          fontSize: 36,
          lineHeight: "45px",
          marginBottom: 16,
        },
        '& > h2': {
          color: '#374151',
          fontWeight: 400,
          fontSize: 18,
          lineHeight: "28px",
          letterSpacing: 'normal',
        },
        '& > button': {

        }
      },
      menuMob: {
        position: 'absolute',
        left: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        '& > span': {
          display: 'flex',
          backgroundColor: 'white',
          color: 'black',
          padding: '10px 20px',
          borderBottom: '1px solid black',
          '&:hover': {
            backgroundColor: 'black',
            color: 'white',
          }
        }
      },
      menu: {
        display: 'none',
      },
      menuIcon: {
        display: 'block'
      },
      container: {
        padding: "16px 20px 48px",
        '& > div': {
          '& > div:last-child': {
            display: 'none'
          }
        }
      },
      blackBtn: {
        padding: '16px 24px',
        fontSize: 16,
        lineHeight: "24px",
        borderRadius: 8
      }
    },
  })
);

const Header = (props: any) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <span className={classes.logo}>
              VITON
            </span>
          </Grid>
          <Grid item className={classes.menu}>
            <span onClick={props.scrollToTry}>
              Use Cases
            </span>
            <span onClick={props.scrollTo360}>
              360 View
            </span>
            <span onClick={props.scrollTo3d}>
              3D Solutions
            </span>
          </Grid>
          <Grid className={classes.menuIcon} onClick={() => setOpen(!open)} item>
            <img src={process.env.PUBLIC_URL + '/img/menu.svg'} alt="" />
          </Grid>
          <Grid item>
            <button className={classes.lightBtn} onClick={props.scrollToOrder}>Contact Us</button>
          </Grid>
        </Grid>
        <div style={{ display: open ? 'flex' : 'none' }} className={classes.menuMob}>
            <span onClick={props.scrollToTry}>
              Use Cases
            </span>
            <span onClick={props.scrollTo360}>
              360 View
            </span>
            <span onClick={props.scrollTo3d}>
              3D Solutions
            </span>
        </div>
        <div className={classes.header}>
          <h1>AR Try On & 3D Preview for any platform</h1>
          <h2>Developing the next generation of immersive e-commerce experiences for stores, mobile apps and websites.</h2>
          <button className={classes.blackBtn} onClick={props.scrollToOrder}>Get a Demo</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
