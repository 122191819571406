import React from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { BccButton, BccTypography } from "./BccComponents";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "100px 40px",
        maxWidth: 1280,
        boxSizing: "border-box",
        '& > h1': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 700,
          fontSize: 32,
          lineHeight: "48px",
          margin: '0 0 48px',
          color: 'black',
          textAlign: 'center',
          letterSpacing: '-2px',
        },
        '& > div': {
          '& > div': {
            width: 'calc(33% - 13px)',
            background: '#F4F4F4',
            padding: '32px 48px',
            borderRadius: 16,
            '& > h2': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 700,
              fontSize: 24,
              lineHeight: "48px",
              margin: '0 0 16px',
              color: 'black',
              letterSpacing: '-2px',
            },
            '& > p': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 500,
              fontSize: 16,
              lineHeight: "32px",
              margin: 0,
              color: 'black',
            },
            '& > span': {
              display: 'block',
              fontFamily: '"Inter", sans-serif',
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "24px",
              color: '#4F4F4F',
              marginTop: 120
            }
          }
        }
      },
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        padding: "48px 20px 0",
        '& > h1': {
          marginBottom: 32,
          textAlign: 'left'
        },
        '& > div': {
          flexDirection: 'column',
          '& > div': {
            width: '100%',
            marginBottom: 24,
            padding: '24px 16px',
            '& > span': {
              marginTop: 16
            }
          },
          '& > div:last-child': {
            marginBottom: 0
          }
        }
      }
    },
  })
);

const Steps = () => {
  const classes = useStyles({});

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <h1>Get Started in 3 Steps</h1>
        <Grid container justify="space-between">
          <Grid item>
            <h2>1. Models</h2>
            <p>Check whether we have your models in our library. If we have, just add them to your account!</p>
            <span>If we don’t have 3D models you need, we will create them for you. We produce 1000s of new 3D models each month for our clients.</span>
          </Grid>
          <Grid item>
            <h2>2. Integrate</h2>
            <p>We will integrate VITON functionality into your website and mobile app using our straightforward yet versatile out-of-the-box plugins, or we can develop a custom integration tailored to your needs. You have the option to enable AR try-on, 3D preview, or both for each of your products.</p>
          </Grid>
          <Grid item>
            <h2>3. Insights</h2>
            <p>Once the plugin is installed, you will start seeing analytics in your VITON dashboard. We will assist you in gathering insights, comparing them to industry benchmarks, and making any necessary adjustments.</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Steps;
