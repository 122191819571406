import React from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import useMobileDetect from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "0 40px 28px",
        maxWidth: 1280,
        boxSizing: "border-box",
      },
      try: {
        '& > div': {
          width: 'calc(50% - 10px)',
          textAlign: 'center',
          marginBottom: 64,
          '& > div': {
            background: '#f2f0f2',
            borderRadius: 24,
            marginBottom: 32,
            padding: '78px 123px 0',
            overflow: 'hidden',
            '& > img': {
              display: 'block',
              width: '100%'
            }
          },
          '& > h3': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 700,
            fontSize: 32,
            lineHeight: "48px",
            letterSpacing: '-2px',
            color: 'black',
            width: '80%',
            margin: '0 auto'
          },
          '& > p': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            fontSize: 20,
            lineHeight: "32px",
            letterSpacing: '-1px',
            color: '#828282',
            margin: '16px auto 32px',
            width: '80%'
          },
          '& > button': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: "48px",
            padding: '0 24px',
            color: '#3988FF',
            background: '#F4F4F4',
            borderRadius: 12,
            cursor: 'pointer',
            border: 'none',
            outline: 'none',
            '&:hover': {
              background: '#E4E4E4',
            },
            '&:active, &:focus': {
              background: '#D4D4D4',
            }
          }
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        padding: "0 20px",
      },
      try: {
        '& > div': {
          width: '100%',
          marginBottom: 40,
          '& > div': {
            padding: '43px 70px 0',
            '& > h3': {
              letterSpacing: '-1px',
            },
            '& > img': {
              width: '80%',
              margin: '0 auto',
            }
          },
          '& > h3': {
            width: '100%',
          },
          '& > p': {
            margin: '16px 0 32px',
            width: '100%'
          }
        }
      }
    },
  })
);

const TryOn = (props: any) => {
  const classes = useStyles({});
  let device = useMobileDetect();
  const try3d = (link: string) => {
    if (!device.isAndroid) return link;
    return `intent://arvr.google.com/scene-viewer/1.0?file=${
      link
    }#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
  }

  return (
    <div className={classes.outerContainer} ref={props.tryRef}>
      <div className={classes.container}>
        <Grid container justify="space-between" alignItems="flex-start" className={classes.try}>
          <Grid item>
            <div>
            <img src={process.env.PUBLIC_URL + '/gif/furniture.gif'} alt="" />
            </div>
            <h3>Furniture Try On</h3>
            <p>Boost sales with 3D and AR virtual try-ons for furniture, enabling customers to see and experience how items will fit and look in their space.</p>
            <button onClick={() => try3d(process.env.PUBLIC_URL + '/ar/chair.gltf')}>Try On in Room</button>
          </Grid>
          <Grid item>
            <div>
            <img src={process.env.PUBLIC_URL + '/gif/playstation.gif'} alt="" />
            </div>
            <h3>Appliances Try On</h3>
            <p>Increase sales by offering 3D and AR virtual try-ons for home appliances, allowing customers to visualize and experience product features in their own space.</p>
            <button onClick={() => try3d(process.env.PUBLIC_URL + '/ar/chair.gltf')}>Try On in Room</button>
          </Grid>
          <Grid item>
            <div>
            <img src={process.env.PUBLIC_URL + '/gif/footwear.gif'} alt="" />
            </div>
            <h3>Footwear Try On</h3>
            <p>Boost sales by using 3D and AR experiences to showcase and highlight unique footwear design features.</p>
            <button onClick={() => try3d(process.env.PUBLIC_URL + '/ar/chair.gltf')}>View at 360</button>
          </Grid>
          <Grid item>
            <div>
              <img src={process.env.PUBLIC_URL + '/gif/accessories.gif'} alt="" />
            </div>
            <h3>Accessories Try On</h3>
            <p>Offer customers a realistic 'try-before-you-buy' experience to understand the sizing, details, and fit of your timepieces as if they're wearing them</p>
            <button onClick={() => try3d(process.env.PUBLIC_URL + '/ar/chair.gltf')}>View at 360</button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TryOn;
