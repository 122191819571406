import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import emailjs from 'emailjs-com';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "100px 40px",
        maxWidth: 1280,
        boxSizing: "border-box",
        '& > h1': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 700,
          fontSize: 48,
          lineHeight: "64px",
          color: 'black',
          marginTop: 48,
          letterSpacing: '-2px',
          textAlign: 'center'
        },
        '& > form': {
          width: '50%',
          margin: '0 auto',
          '& > input': {
            display: 'block',
            width: '100%',
            background: '#F4F4F4',
            height: 48,
            borderRadius: 12,
            padding: '12px 24px',
            boxSizing: 'border-box',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: "24px",
            border: 'none',
            marginBottom: 16
          },
          '& > textarea': {
            display: 'block',
            width: '100%',
            background: '#F4F4F4',
            borderRadius: 12,
            padding: '12px 24px',
            boxSizing: 'border-box',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: "48px",
            border: 'none',
            marginBottom: 24,
            resize: 'none'
          },
          '& > p': {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: "24px",
            color: '#5F5F5F',
            '& > a': {
              color: '#5F5F5F',
            }
          }
        },
      },
      btn: {
        width: '100%',
        fontFamily: '"Inter", sans-serif',
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "40px",
        padding: '12px 0',
        textAlign: 'center',
        color: 'white',
        background: 'black',
        borderRadius: 12,
        marginBottom: 24,
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        '&:hover': {
          background: '#606060',
        },
        '&:active, &:focus': {
          background: '#9B9B9B',
        }
      },
      sBtn: {
        width: '100%',
        fontFamily: '"Inter", sans-serif',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "32px",
        padding: '12px 24px',
        textAlign: 'center',
        color: 'white',
        background: 'black',
        borderRadius: 12,
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        '& > img': {
          marginRight: 8
        }
      },
      snackbar: {
        '& > div': {
          background: 'none',
          boxShadow: 'none',
          padding: 0,
          minWidth: 'auto',
        }
      }
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        padding: "48px 20px",
        '& > h1': {
          margin: '0 0 16px',
          fontSize: 32,
          lineHeight: "48px",
          textAlign: 'left',
        },
        '& > p': {
          margin: '0 0 24px',
          fontFamily: '"Inter", sans-serif',
          fontWeight: 400,
          fontSize: 18,
          lineHeight: "28px",
          color: '#374151'
        },
        '& > form': {
          width: '100%',
          '& > input': {
            padding: 16
          },
          '& > textarea': {
            padding: '16px 24px',
            lineHeight: "32px",
            minHeight: 144
          },
          '& > button': {
            borderRadius: 8,
            fontSize: 16,
            lineHeight: "24px",
          },
          '& > p': {
            margin: 0
          }
        }
      },
    }
  })
);

const Get = (props: any) => {
  const classes = useStyles({});
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [details, setDetails] = useState("");

  const submit = (e: any) => {
    e.preventDefault();
    emailjs.send('service_1plphht', 'template_18h4dpt', {
      name,
      email,
      company,
      role,
      details
    }, 'uhWISplSXsw_7u_DJ')
      .then(() => {
          setName("");
          setEmail("");
          setCompany("");
          setRole("");
          setDetails("");
          setOpen(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  }

  return (
    <div className={classes.outerContainer} ref={props.orderRef}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.snackbar}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        action={
          <React.Fragment>
            <button className={classes.sBtn}><img src={process.env.PUBLIC_URL + '/img/mark.png'} alt="" />Your request sent</button>
          </React.Fragment>
        }
      />
      <div className={classes.container}>
        <h1>Get a demo</h1>
        {isXs && (<p>Upload your assets, link them to your website or app through API, track analytics</p>)}
        <form onSubmit={submit} action="">
          <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
          <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="text" placeholder="Company" value={company} onChange={(e) => setCompany(e.target.value)} />
          <input type="text" placeholder="Your role" value={role} onChange={(e) => setRole(e.target.value)} />
          <textarea name="" value={details} placeholder="Any more detail you would like to share (optional)" id="" onChange={(e) => setDetails(e.target.value)}></textarea>
          <button className={classes.btn} type="submit">Submit</button>
          <p>This site is protected by reCAPTCHA and the Google <a href="">Privacy Policy</a> and <a href="">Terms of Service</a> apply.</p>
        </form>
      </div>
    </div>
  );
};

export default Get;
