import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down("xl")]: {
      outerContainer: {
        background: `#fff`,
      },
      container: {
        position: "relative",
        margin: "0 auto",
        padding: "0 40px 100px",
        maxWidth: 1280,
        boxSizing: "border-box",
        '& > div': {
          '& > div:last-child': {
            paddingLeft: 126,
            '& > h1': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 700,
              fontSize: 32,
              lineHeight: "48px",
              margin: '0 0 24px',
              color: 'black',
              letterSpacing: '-2px',
            },
            '& > p': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 400,
              fontSize: 18,
              lineHeight: "28px",
              margin: '0 0 48px',
              color: '#374151',
            },
            '& > button': {
              fontFamily: '"Inter", sans-serif',
              fontWeight: 600,
              fontSize: 16,
              lineHeight: "48px",
              padding: '0 24px',
              color: '#3988FF',
              background: '#F4F4F4',
              borderRadius: 12,
              marginBottom: 32,
              cursor: 'pointer',
              border: 'none',
              outline: 'none',
              '&:hover': {
                background: '#E4E4E4',
              },
              '&:active, &:focus': {
                background: '#D4D4D4',
              }
            }
          }
        }
      },
    },
    [theme.breakpoints.down("xs")]: {
      container: {
        padding: "48px 20px",
        '& > div': {
          flexDirection: 'column',
          '& > div:last-child': {
            padding: 0,
            marginTop: 32,
            '& > p': {
              marginBottom: 24
            },
            '& > button': {
              marginBottom: 0
            }
          }
        },
      }
    },
  })
);

const AllInOne = (props: any) => {
  const classes = useStyles({});
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.outerContainer} ref={props.solutionRef}>
      <div className={classes.container}>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            {isXs ? <img src={process.env.PUBLIC_URL + '/img/all-m.svg'} alt="" /> : <img src={process.env.PUBLIC_URL + '/img/all.svg'} alt="" />}
          </Grid>
          <Grid item>
            <h1>All-in-One place<br/>for your AR and 3D models</h1>
            <p>Upload your assets, link them to your website or app through API, track analytics</p>
            <button>Know More</button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AllInOne;
